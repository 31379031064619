<template>
  <div class="invoices-index width-100">
    <TableWrapper
      :table-columns="invoicesColumns"
      table-key="invoices"
      :enable-selection="false"
      @showInvoice="showInvoice"
      @showAttachment="showAttachment"
    />
  </div>
</template>

<script>
import fileSaver from 'file-saver';
import { mapActions, mapGetters } from 'vuex';
import { invoicesColumns } from '@/config';
import { invoicesApi } from '@/api';
import TableWrapper from '@/components/TableWrapper.vue';

export default {
  name: 'InvoicesIndex',
  components: {
    TableWrapper,
  },
  data() {
    return {
      invoicesColumns,
    };
  },
  computed: {
    ...mapGetters([
      'filtersStatus',
      'tableFilters',
    ]),
  },
  created() {
    this.parseQueryStringStatus(this.$route.query);
    this.tableRows();
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryStringStatus(to.query);
    this.tableRows();
    next();
  },
  methods: {
    ...mapActions([
      'updateTableRows',
      'updateTableCount',
      'parseQueryStringStatus',
    ]),
    async tableRows() {
      try {
        const invoicesIndexResponse = await invoicesApi.index(this.tableFilters('invoices'), this.filtersStatus.offers, true);
        const invoicesCountResponse = await invoicesApi.count(this.filtersStatus.offers, true);
        this.updateTableRows({ tableKey: 'invoices', rows: invoicesIndexResponse.data });
        this.updateTableCount({ tableKey: 'invoices', count: invoicesCountResponse.data.count });
      } catch (error) {
        throw error;
      }
    },
    async showInvoice(row) {
      try {
        this.loading = true;
        const file = await invoicesApi.getInvoicePdf(row.orderId);
        if (file.data) {
          return fileSaver.saveAs(URL.createObjectURL(file.data), `fattura-${row.orderId}.pdf`);
        }
        this.loading = false;
        return file;
      } catch (error) {
        this.loading = false;
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
        return error;
      }
    },
    async showAttachment(row) {
      try {
        this.loading = true;
        const file = await invoicesApi.getAttachmentPdf(row.orderId);
        if (file.data) {
          return fileSaver.saveAs(URL.createObjectURL(file.data), `allegato-${row.orderId}.pdf`);
        }
        this.loading = false;
        return file;
      } catch (error) {
        this.loading = false;
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
        return error;
      }
    },
  },
};
</script>
